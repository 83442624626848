import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular'; // For Loading Option
import { ToastController } from '@ionic/angular'; // For Alert Option
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; // For Http Request
import { Subject } from 'rxjs';

let baseURL = "http://3.111.171.235/api/";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  myToast: any = '';
  isLoading: boolean = false;
  userSubject = new Subject<string>();
  constructor(
    private loadingCtrl: LoadingController, 
    private toastCtrl: ToastController, 
    private http: HttpClient,
  ) {

  }

  async presentLoading(loadTime = 5000) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      cssClass: "custom-load",
      //duration: loadTime
    })
    .then(a => {
      a.present().then(() => {
        console.log("presented");
        if (!this.isLoading) {
            a.dismiss().then(() => console.log("closed"));
        }
      });
    });
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl
      .dismiss()
      .then(() => console.log("dissmisses"));
  }

  async successToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      color: 'dark',
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      color: 'danger',
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  // Se Item in Localstorage
  setItem(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  getItem(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  setToken(data) {
    localStorage.setItem("token", JSON.stringify(data));
  }

  getToken() {
    return JSON.parse(localStorage.getItem('token'));
  }

  // HTTP Functions
  login(data) {
    return this.http.post(baseURL + "auth/Login", data);
  }

  vehicleSearch(data, headers) {
    return this.http.get(baseURL + "info/VehicleSearchBy?Type="+data[0]+"&val="+data[1]+"", {headers: headers});
  }

  vehicleDetails(id, headers) {
    return this.http.get(baseURL + "info/VechicleDetails?Id="+id+"", {headers: headers});
  }


  logout() {
    localStorage.clear();
  }

  // End of Class
}

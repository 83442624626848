import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { ApiService } from './api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userInfo: any = '';
  constructor(
    private router: Router,
    public api: ApiService,
    private alertController: AlertController,
    private platform: Platform,
  ) {
    this.initializeApp();
    var _self = this;
    _self.api.userSubject.subscribe((data) => {
        _self.userInfo = data;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
     
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Are You Sure?',
        message: 'You will be <strong>Logged Out</strong>!!!',
        buttons: [{
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
                //console.log('Confirm Cancel: blah');
            }
        }, {
            text: 'Okay',
            handler: () => {

                this.api.logout();
                this.router.navigate(['/']);

            }
        }]
    });

    await alert.present();
  }

  doLogout() {
    this.presentAlertConfirm();
  }
  // End of class
}
